import { useState, useEffect } from "react";
import Image from "next/image";
import Slider from "react-slick";
import { gsap } from "gsap";
import { getFile } from "@sanity/asset-utils";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import { useRouter } from "next/router";
import { useIntl } from "react-intl";
import { useNextSanityImage } from "next-sanity-image";
import { FormattedMessage } from "react-intl";
import client from "../../client";
import SectionHeader from "../shared/SectionHeader";
import Button from "../shared/Button";
import {
  SliderCustomPreviousArrow,
  SliderCustomNextArrow,
} from "../shared/carousel";

const BlockContent = require("@sanity/block-content-to-react");

function ResearchPapers({ data }) {
  for (let i = 0; i < data.slides.length; i++) {
    data.slides[i].images = {
      mobile: useNextSanityImage(client, data.slides[i].mobile_image),
      desktop: useNextSanityImage(client, data.slides[i].desktop_image),
    };
  }

  const windowWidth = useWindowWidth();
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const router = useRouter();
  const intl = useIntl();

  useEffect(() => {
    if (windowWidth >= 1280) {
      setIsMobile(false);
      setIsTablet(false);
      setIsDesktop(true);
    } else if (windowWidth >= 1024) {
      setIsMobile(false);
      setIsTablet(true);
      setIsDesktop(false);
    } else {
      setIsMobile(true);
      setIsTablet(false);
      setIsDesktop(false);
    }
  }, [windowWidth]);

  return (
    <section
      id="our-business"
      className="section our-business"
      data-side-menu-label={intl.formatMessage({
        id: "science.publications.title",
        defaultMessage: "Publications",
      })}
      data-side-menu-color="light"
      data-side-menu-visibility="visible"
      data-header-menu-visibility="visible"
    >
      {isMobile && (
        <div>
          <div className="items text-white bg-gray-800 text-center relative">
            <div className="image animate opacity-0 absolute w-full h-2/3-screen">
              <Image
                src={data.slides[0].images.mobile.src}
                alt={intl.formatMessage({
                  id: "science.research.title",
                  defaultMessage: "Research Papers",
                })}
                layout="fill"
                objectFit="cover"
                quality={100}
              />
            </div>
            <div
              className="item"
              key={data.title}
              dir={router.locale === "he" ? "rtl" : "ltr"}
            >
              <div className="relative text-center w-full h-2/3-screen lg:text-start lg:p-5 lg:border-t-2 lg:border-white flex flex-wrap content-end">
                <div className="container mx-auto px-16 py-8 lg:flex lg:flex-row lg:space-x-20 z-10"></div>
              </div>
            </div>
          </div>
          <div>
            <div className="container mx-auto px-8 py-8">
              <SectionHeader name={data.name} title={<h2>{data.title}</h2>} />
              <div className="text animate opacity-0  lg:text-epm-base lg:w-103">
                <BlockContent blocks={data.content} className="external-text" />
              </div>
            </div>
          </div>
        </div>
      )}

      {(isTablet || isDesktop) && (
        <div className="h-screen relative">
          <div className="h-2/3-screen ">
            <div
              id="research-papers-list"
              className="relative z-10 w-full h-full text-start flex flex-wrap content-end"
            >
              <div className="absolute w-full h-full">
                <Image
                  src={data.slides[0].images.desktop.src}
                  alt={intl.formatMessage({
                    id: "science.research.title",
                    defaultMessage: "Research Papers",
                  })}
                  layout="fill"
                  objectFit="cover"
                  quality={100}
                />
              </div>
            </div>
          </div>
          <div className="h-1/3-screen">
            <div className="container mx-auto px-8 lg:ps-24 xl:ps-56 2xl:px-8 py-8 lg:h-1/3-screen lg:flex lg:flex-col">
              <div>
                <SectionHeader
                  name={<span>{data.name}</span>}
                  title={<h2>{data.title}</h2>}
                />
              </div>

              <div className="animate opacity-0 text mt-6 lg:w-103">
                <BlockContent blocks={data.content} className="external-text" />
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default ResearchPapers;
