import { useState, useEffect, useRef } from "react";
import Image from "next/image";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import { gsap } from "gsap";
import Link from "next/link";
import { useIntl } from "react-intl";
import { useRouter } from "next/router";

import Button from "../shared/Button";

function Main({ data }) {
  const windowWidth = useWindowWidth();
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const intl = useIntl();
  const router = useRouter();
  const ref = useRef();

  useEffect(() => {
    if (ref && ref.current) {
      const image = ref.current.querySelector("img");

      if (image) {
        image.style.objectPosition = "bottom";
      }
    }
  }, [ref, isMobile, isTablet, isDesktop]);

  useEffect(() => {
    if (windowWidth >= 1280) {
      setIsMobile(false);
      setIsTablet(false);
      setIsDesktop(true);
    } else if (windowWidth >= 1024) {
      setIsMobile(false);
      setIsTablet(true);
      setIsDesktop(false);
    } else {
      setIsMobile(true);
      setIsTablet(false);
      setIsDesktop(false);
    }
  }, [windowWidth]);

  useEffect(() => {
    setTimeout(() => {
      const tl = gsap.timeline();

      tl.to(".scroll-to-content", {
        duration: 2,
        y: "-=10px",
        yoyo: true,
        repeat: -1,
        ease: "easeInOut",
      });
    });
  }, []);

  const scrollToContent = (event) => {
    event.preventDefault();
    document.querySelector("#story").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <section
      id="main"
      className="section main w-full bg-cover text-center  relative lg:h-screen"
      data-side-menu-color="light"
      data-side-menu-visibility="visible"
      data-header-menu-visibility="hidden"
    >
      <div className="relative lg:h-screen">
        <div className="video lg:h-screen" ref={ref}>
          {isMobile && (
            <Image
              loading="eager"
              src="/img/mobile/homepage/hero@2x.png"
              alt={intl.formatMessage({
                id: "home.hero.title",
                defaultMessage: "Medicine to All",
              })}
              width="375"
              height="812"
              quality={100}
            />
          )}
          {(isTablet || isDesktop) && (
            <Image
              loading="eager"
              src="/img/desktop/homepage/hero@2x.jpg"
              alt={data.name}
              layout="fill"
              objectFit="cover"
              objectPosition="bottom"
              quality={100}
            />
          )}
        </div>

        <div className="absolute bottom-0 w-full py-12">
          <div className="container mx-auto px-8 py-8 text-white ">
            <div className="main__image flex flex-col md:flex-row justify-center items-center">
              <a
                href="https://likomint.com/"
                target="_blank"
                rel="noopener"
                className="transition-opacity duration-150 hover:opacity-90"
              >
                {isMobile && (
                  <Image
                    src="/img/icons/homepage/mint-fashion.png"
                    alt={data.title}
                    width="200"
                    height="75"
                    loading="eager"
                  />
                )}
                {(isTablet || isDesktop) && (
                  <Image
                    src="/img/icons/homepage/mint-fashion.png"
                    alt={data.title}
                    width="267"
                    height="100"
                    loading="eager"
                  />
                )}
              </a>

              <a
                href="https://likomerch.com/"
                target="_blank"
                rel="noopener"
                className="transition-opacity duration-150 hover:opacity-90"
              >
                {isMobile && (
                  <Image
                    src="/img/icons/homepage/mint-merch.png"
                    alt={data.title}
                    width="200"
                    height="75"
                    loading="eager"
                  />
                )}
                {(isTablet || isDesktop) && (
                  <Image
                    src="/img/icons/homepage/mint-merch.png"
                    alt={data.title}
                    width="267"
                    height="100"
                    loading="eager"
                  />
                )}
              </a>
            </div>
            <p className="main__text font-title lg:text-lg lg:w-sm-container lg:mx-auto mb-[30px] z-10lg:mb-[45px]">
              {data.text}
            </p>
            <h1 className="main__title font-title text-4xl lg:text-6xl lg:leading-tight tracking-wide mb-4">
              {data.name}
            </h1>
            <p className="main__tagline lg:text-3xl lg:font-light lg:w-sm-container lg:mx-auto">
              {data.title}
            </p>
          </div>
          <div className="scroll-to-content ">
            <a
              href="#one-of-us"
              className="transition-opacity duration-150 hover:opacity-70"
              onClick={scrollToContent}
            >
              <Image
                src="/img/icons/arrow_down.svg"
                alt={data.title}
                width="28"
                height="16"
                loading="eager"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Main;
