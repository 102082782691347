import { useState, useEffect } from "react";
import Image from "next/image";
import { gsap } from "gsap";
import { useNextSanityImage } from "next-sanity-image";
import classNames from "classnames";
import client from "../../client";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import { useRouter } from "next/router";
import SectionHeader from "../shared/SectionHeader";
import Button from "../shared/Button";
import { useIntl } from "react-intl";

const BlockContent = require("@sanity/block-content-to-react");

function OurMission({ data }) {
  const windowWidth = useWindowWidth();
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const router = useRouter();
  const intl = useIntl();
  const atmosphere = {
    mobile: useNextSanityImage(client, data.mobile_image),
    desktop: useNextSanityImage(client, data.desktop_image),
  };

  useEffect(() => {
    if (windowWidth >= 1280) {
      setIsMobile(false);
      setIsTablet(false);
      setIsDesktop(true);
    } else if (windowWidth >= 1024) {
      setIsMobile(false);
      setIsTablet(true);
      setIsDesktop(false);
    } else {
      setIsMobile(true);
      setIsTablet(false);
      setIsDesktop(false);
    }
  }, [windowWidth]);

  return (
    <section
      id="our-mission"
      className="section our-mission"
      data-side-menu-label={data.name}
      data-side-menu-color="light"
      data-side-menu-visibility="visible"
      data-header-menu-visibility="visible"
    >
      {isMobile && (
        <div>
          <div className="items relative text-white bg-gray-300 w-full text-center flex flex-col justify-center items-center h-2/3-screen">
            <div className="absolute w-full h-full">
              <Image
                src={atmosphere.mobile.src}
                alt={data.title}
                layout="fill"
                objectFit="cover"
                quality={100}
              />
            </div>
          </div>
          <div className="lg:h-1/3-screen">
            <div className="container mx-auto px-8 py-8">
              <div className="mb-6">
                <SectionHeader name={data.name} title={<h2>{data.title}</h2>} />
              </div>
              <div className="text lg:w-103">
                <BlockContent blocks={data.content} className="external-text" />
              </div>
              <div className="button pt-10">
                <Button
                  href={
                    router.locale === "en"
                      ? "/business/#main"
                      : "/he/business/#main"
                  }
                  style="dark"
                >
                  {data.button}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {(isTablet || isDesktop) && (
        <div className="relative h-screen text-white flex flex-col justify-between">
          <div className="absolute w-full h-full">
            <Image
              src={atmosphere.desktop.src}
              alt={data.title}
              layout="fill"
              objectFit="cover"
              quality={100}
            />
          </div>
          <div className="container relative mx-auto text-center px-8 py-8">
            <div className="text animate opacity-0 font-light mx-auto lg:w-105">
              <BlockContent blocks={data.content} className="external-text" />
            </div>
            <div className="button animate opacity-0 pt-10">
              <Button
                href={
                  router.locale === "en"
                    ? "/business/#main"
                    : "/he/business/#main"
                }
                style="light"
              >
                {data.button}
              </Button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default OurMission;
